<script setup>
import { TransitionRoot } from "@headlessui/vue";
import { useWindowScroll } from "@vueuse/core";
import { computed } from "vue";

const { y } = useWindowScroll();

const show = computed(() => {
  return y.value > 100;
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

</script>
<template>
  <TransitionRoot
    :show="!!show"
    class="fixed bottom-12 right-12 w-[50px] h-[50px]
    text-2xl text-white
    bg-lime-700
    flex items-center justify-center
    cursor-pointer
    border border-white rounded-full"
    enter="transition-opacity duration-500"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
    @click="scrollToTop"
  >
    <span>⇪</span>
  </TransitionRoot>
</template>